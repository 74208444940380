<template>
  <v-app>
    <v-main>
      <v-container
        class="text-center fill-height"
      >
        <v-row align="center">
          <v-col>
            <v-img height="500" src="@/assets/pnstidur.svg" contain></v-img>
            <h1 class="display-2 primary--text">
              Whoops, 404
            </h1>

            <p>Halaman yang anda cari tidak ada. Pastikan Url anda benar.</p>

            <v-btn
              @click="goBack()"
              color="primary"
              outlined
            >
              Kembali ke halaman sebelumnya
            </v-btn>
            <v-btn
              to="/"
              color="blue"
              class="ml-2"
              outlined
            >
              Kembali ke portal
            </v-btn>
            <v-btn
              color="red"
              @click="gotoLogin()"
              outlined
            >
              Kembali ke halaman login
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import store from '@/store'
  import router from '@/router'
  export default {
    name: 'FourOhFour',
    props: {
      lang: {
        type: String,
        default: 'en',
      },
    },
    created () {
      console.log('called 404')
      store.commit('routes/RESET_LOOP_GUARD')
      this.$ssrContext && this.$ssrContext.res.status(404)
    },
    methods:{
      goBack(){
        router.go(-1)
      },
      gotoLogin(){
        clearTimeout(this.timeout)
        store.commit('user/SET_ONGOING', false)
        router.push('/login')
      }
    }
  }
</script>
